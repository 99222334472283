<template>
    <div style="max-width: 400px; margin: 10vh auto;" class="text-center">
        <br>
        <img src="@/assets/404-image-1.png" class="img-fluid"/>
        <br>
        <br>
        <h1>Pagina non trovata</h1>
        <br>
        <router-link class="btn btn-secondary" to="/">Torna alla home</router-link>
    </div>

</template>

<script>
export default {
    name: "Home",
}
</script>

<style scoped>

</style>
